<template>
  <div class="add-form">
    <el-form label-position="top" size="small">
      <el-form-item :label="$t('location')" required>
        <checkout-list :data.sync="locationList" />
        <span class="help">{{ $t("tips.location") }}</span>
      </el-form-item>

      <el-form-item :label="$t('Namespace')" required>
        <type-list type="namespace" :data.sync="serviceForm.metadata.namespace" :locationList="locationList" />
        <span class="help">{{ $t("tips.checkoutNamespace") }}</span>
      </el-form-item>

      <service-conf ref="serviceForm" :serviceForm="serviceForm" :namespace="namespace" :locationList="locationList" />

      <el-form-item label="Pod选择器">
        <div slot="label">
          <div>Pod选择器</div>
          <span class="help">以标签的方式选择一组pod，统一对外暴露提供服务，填写目标 Pod 的Label</span>
        </div>

        <edit-labels :data="serviceForm.spec.selector" ref="selector" />
      </el-form-item>

      <el-button type="primary" size="small" @click="submit" :loading="submitLoading">
        {{ $t("handle.submit") }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import ServiceConf from "@/pages/Application/Add/ServiceConf";
import CheckoutList from "@/components/CheckoutList";

import { transform } from "lodash";

import { serviceSubmit } from "api/service";

export default {
  components: {
    ServiceConf,
    CheckoutList
  },

  data() {
    return {
      locationList: [],

      serviceForm: {
        metadata: {
          name: "",
          namespace: ""
        },
        spec: {
          ports: [
            {
              name: "port1",
              port: 8080,
              protocol: "TCP"
            }
          ],
          type: "ClusterIP",
          selector: {}
        }
      },

      submitLoading: false
    };
  },

  computed: {
    vendor() {
      return this.locationList[0];
    },

    region() {
      return this.locationList[1];
    },

    cluster() {
      return this.locationList[2];
    }
  },

  methods: {
    submit() {
      this.$refs["serviceForm"].$refs["form"].validate(valid => {
        if (valid) {
          this.$refs["selector"].$refs["form"].validate(selectorValid => {
            if (selectorValid) {
              this.submitLoading = true;
              this.serviceForm.metadata.name = this.$refs["serviceForm"].form.metadata.name;
              this.serviceForm.spec = this.$refs["serviceForm"].form.spec;

              let selector = transform(
                this.$refs["selector"].form.labels,
                function(result, item) {
                  result[item.key] = item.value;
                },
                {}
              );

              this.serviceForm.spec.selector = selector;

              serviceSubmit(this.serviceForm, {
                vendor: this.vendor,
                region: this.region,
                cluster: this.cluster
              }).then(response => {
                this.submitLoading = false;
                if (response.code === 0) {
                  this.$router.push({ path: `/list/Service` });
                }
              });
            }
          });
        }
      });
    }
  }
};
</script>

<style></style>
